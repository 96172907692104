.state_switch {
    background-color: #1D2020;
    width: 100%;
    grid-column: 1 / span 3;
    grid-row: 2;
    display: flex;
}

.sw_button {
    flex: 1 1;
    color: #BDD8C7;
    padding: 10px;
}

.sw_button:hover {
    cursor: pointer;
}

.active_list {
    background-color: #FF715B;
}