.landing {
    background: linear-gradient(0deg, #1D2020, #272B2B);
}

.landing_nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.nav-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 5px;
    list-style: none;
}

.nav-list a {
    text-decoration: none;
    color: #FFA799;
    margin: 5px;
    padding: 5px;
    font-style: italic;
}

.landing_header {
    height: 70vh;
    display: grid;
    place-items: center;
    text-align: center;
}

.landing_header h1 {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 800;
    font-size: xxx-large;
    color: #FF715B;
    filter: drop-shadow(3px 5px 0px #2F4A4C);
}

.landing_header p {
    color: #FFA799;
    filter: drop-shadow(2px 3px 0px #2F4A4C);
    font-size: larger;
}

.landing_main {
    display: grid;
    place-items: center;
    color: white;
    height: auto;
    margin-top: 10rem;
    padding: 0 10vw 5vh;
}

.github_link {
    text-decoration: none;
    color: #272B2B;
    font-size: large;
    font-weight: bold;
}

.github_button {
    background-color: #FF715B;
    border: none;
    padding: 1em;
    border-radius: .5em;
    margin: 1em;
    box-shadow: 0px 5px 0px 2px #2F4A4C;
    transition: all .2s ease;
}

.github_button:active {
    box-shadow: 0px 1px 0px 2px #2F4A4C;
    transform: translateY(4px);
}

.github_button:hover {
    background-color: #FFA799;
}

.landing_main_container {
    text-align: center;
    line-height: 3em;
}

.demo_creds {
    color: white;
    background-color: #456b6b5c;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem 10em;
    border-radius: 1rem;
    z-index: 10;
    margin: -8rem auto;
    text-align: center;
    box-shadow: 5px 5px 5px 0px #272b2b;
}

@media only screen and (max-width: 500px) {
    .demo_creds {
        padding: 1rem 20vw;
    }
}