.shift_history {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 20px;
}

.correct_background {
    background: linear-gradient(0deg, #1D2020, #272B2B);
}

.history_list_item {
    font-family: 'Segoe UI', sans-serif;
    margin:10px;
    padding: 5px 5px 5px;
    flex: 0 1 300px;
    display: grid;
    grid-template: auto 1fr auto / auto 1fr auto;
    background: linear-gradient(45deg, #2F4A4C, #456F6B);
    border-radius: 7px;
    padding: 10px;
    color: #5A9389;
    box-shadow: 0 0 20px 10px #1D2020;
}

.list_date {
    grid-column: 3;
    font-style: italic;
    font-weight: 500;
}

.list_tips {
    grid-column: 1 / span 3;
    grid-row: 2;
    padding: 20px;
    justify-self: center;
    font-size: larger;
    font-weight: 700;
    font-style: italic;
}

.tip_row {
    grid-column: 1;
    grid-row: 2;
    justify-self: flex-end;
    align-self: center;
    border-right: 1px solid #212121;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 25px;
}

.hour_row {
    grid-column: 1;
    grid-row: 3;
    justify-self: flex-end;
    padding-left: 10px;
    border-right: 1px solid #212121;
    padding-right: 10px;
}


.list_hours {
    grid-column: 1 / span 3;
    grid-row: 3;
    justify-self: center;
    font-size: larger;
    font-weight: 700;
    font-style: italic;
}

.list_job {
    grid-column: 1 / span 2;
    grid-row: 1;
    padding-bottom: 5px;
    font-style: italic;
}

.list_buttons {
    grid-column: 3;
    grid-row: 3;
    justify-self: flex-end;
    align-self: flex-end;
    padding: 5px;
}

.fa_buttons {
    color: #CAE6DB;
}

.fa_buttons:hover {
    cursor: pointer;
}

.fa_buttons:active {
    color: #FF715B;
}

.instructions {
    text-align: center;
    margin: 100px auto;
    width: 75%;
}

.bittersweet {
    color: #FF715B;
}

.setup_container {
    text-align: -webkit-center;
    justify-content: center;
}