.dashboard_header {
    background-color: #FF715B;
    height: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.logo {
    font-family: 'Segoe UI', sans-serif;
    font-style: italic;
    font-weight: 800;
    color: #1D2020;
    margin: 0 10px;
    font-size: xx-large;
    justify-content: flex-end;
}

.dashboard_header a {
    text-decoration: none;
    color: black;
    margin-right: 10px;
    font-style: italic;
}

.dashboard_header a:hover {
    cursor: pointer;
}

.hamburger {
    margin: 15px;
    font-size: larger;
}

.hamburger:hover {
    cursor: pointer;
}

.header_logged_in {
    margin-bottom: 15px;
}