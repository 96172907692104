.rego_form button {
    grid-column: 1 / span 2;
}

.alert {
    grid-column: 1 / span 2;
    justify-self: center;
    max-width: 300px;
    color: #FF715B;
    font-weight: bold;
    text-align: center;
    margin: 10px auto;
}