.dashboard_main {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-template-rows: auto;
}

.ehr_box h2 {
    margin: 35px auto 0px;
}

.ehr_box p {
    margin: 0px;
}

.list_container {
    grid-column: 1 / span 3;
    grid-row: 3;
    align-self: center;
}

.add_income_button a {
    text-decoration: none;
    color: black;
    margin: 10px;
    padding: 10px;
}

.add_income_button {
    position: fixed;
    right: 0;
    bottom: 0px;
    z-index: 1;
}

.no_selection_button {
    background-color: #84DCC6;
    margin: 10px;
    padding: 10px;
    color: #2F4A4C;
    border-radius: 50%;
}

.no_selection_button:hover {
    cursor: pointer;
}

.no_selection_button:active {
    filter: none;
    background-color: #FF715B;
}

.income_selection button {
    background-color: #FF715B;
    border: none;
    color: #1D2020;
    border-radius: 5px;
    padding: 10px;
    font-size: large;
    margin: 10px;
    filter: drop-shadow(2px 2px 2px #1D2020);
}

.income_selection button:hover {
    cursor: pointer;
}

.income_selection button:active {
    filter: none;
    color: #FF715B;
    background-color: #1D2020;
}

.close_shift_window {
    position: absolute;
    right: 34px;
    top: 34px;
}

.close_shift_window:hover {
    cursor: pointer;
}

.close_check_window {
    position: absolute;
    right: 34px;
    top: 34px;
}

.close_check_window:hover {
    cursor: pointer;
}

.job_form button {
    grid-column: 1 / span 2;
}

.role_form button {
    grid-column: 1 / span 2;
}

.info_container {
    grid-column: 1 / span 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.info_box {
    flex: 1 1 100px;
    padding: 2px;
    text-align: center;
    color: #BDD8C7;
}

.loading_spinner {
    color: #BDD8C7;
    margin: 20px auto;
    display: block;
    height: -webkit-fill-available;
}